<template>
    <div class="section">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item :label="$t('username')" prop="username">
                <el-input v-model="ruleForm.username"></el-input>
            </el-form-item>
            <el-form-item :label="$t('password')" prop="pwd">
                <el-input v-model="ruleForm.pwd"></el-input>
            </el-form-item>

            <el-form-item :label="$t('first_name')" prop="first_name">
                <el-input v-model="ruleForm.first_name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('surname')" prop="last_name">
                <el-input v-model="ruleForm.last_name"></el-input>
            </el-form-item>

            <el-form-item prop="date1" :label="$t('birth_date')">
                <el-date-picker type="date" placeholder="Pick a date" v-model="ruleForm.date1"
                    style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('address')" prop="location">
                <el-input type="textarea" v-model="ruleForm.location"></el-input>
            </el-form-item>
            <el-form-item :label="$t('id')" prop="did">
                <el-input v-model="ruleForm.did"></el-input>
            </el-form-item>

            <el-form-item :label="$t('gender')">
                <el-select v-model="ruleForm.gender" clearable placeholder="Select">
                    <el-option :label="$t('female')" :value="0">
                    </el-option>
                    <el-option :label="$t('male')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('heart')">
                <el-select v-model="ruleForm.heart" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('breast')">
                <el-select v-model="ruleForm.breast" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('diabets')">
                <el-select v-model="ruleForm.diabets" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('asthma')">
                <el-select v-model="ruleForm.asthma" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('hypertension')">
                <el-select v-model="ruleForm.hypertension" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('medicines')">
                <el-select v-model="ruleForm.medicines" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('allergia')">
                <el-select v-model="ruleForm.allergies" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('operations')">
                <el-select v-model="ruleForm.operations" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('disease')">
                <el-select v-model="ruleForm.diseases" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('history_diabets')">
                <el-select v-model="ruleForm.hereditary_diabetes" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item :label="$t('smoke')">
                <el-select v-model="ruleForm.smoke" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                    <el-option :label="$t('smoke_quit')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('alcohol')">
                <el-select v-model="ruleForm.alcohol" clearable placeholder="Select">
                    <el-option :label="$t('never')" :value="0">
                    </el-option>
                    <el-option :label="$t('rarely')" :value="1">
                    </el-option>
                    <el-option :label="$t('regular')" :value="2">
                    </el-option>
                    <el-option :label="$t('often')" :value="3">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('sport')">
                <el-select v-model="ruleForm.sport" clearable placeholder="Select">
                    <el-option :label="$t('never')" :value="0">
                    </el-option>
                    <el-option :label="$t('rarely')" :value="1">
                    </el-option>
                    <el-option :label="$t('aweek')" :value="2">
                    </el-option>
                    <el-option :label="$t('everyday')" :value="3">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('diet')">
                <el-select v-model="ruleForm.nutrition" clearable placeholder="Select">
                    <el-option :label="$t('other')" :value="0">
                    </el-option>
                    <el-option :label="$t('normal')" :value="1">
                    </el-option>
                    <el-option :label="$t('vegetarian')" :value="2">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('eat')">
                <el-select v-model="ruleForm.eat" clearable placeholder="Select">
                    <el-option :label="$t('other')" :value="0">
                    </el-option>
                    <el-option :label="$t('1time')" :value="1">
                    </el-option>
                    <el-option :label="$t('2time')" :value="2">
                    </el-option>
                    <el-option :label="$t('3time')" :value="3">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('depression')">
                <el-select v-model="ruleForm.depression" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('emotional')">
                <el-select v-model="ruleForm.emotional_state" clearable placeholder="Select">
                    <el-option :label="$t('poor')" :value="0">
                    </el-option>
                    <el-option :label="$t('satisfactory')" :value="1">
                    </el-option>
                    <el-option :label="$t('good')" :value="2">
                    </el-option>
                    <el-option :label="$t('excellent')" :value="3">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('drugs')">
                <el-select v-model="ruleForm.psychotropic_drugs" clearable placeholder="Select">
                    <el-option :label="$t('no')" :value="0">
                    </el-option>
                    <el-option :label="$t('yes')" :value="1">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item :label="$t('avatar')">
                <input type='file' id="avatar" />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('create') }}</el-button>
                <el-button @click="resetForm('ruleForm')">{{ $t('reset') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            imageUrl: '',
            ruleForm: {
                username: '',
                first_name: '',
                last_name: '',
                date1: '',
                location: '',
                pwd: '',
                did: '',
                psychotropic_drugs: 0,
                emotional_state: 2,
                depression: 0,
                eat: 3,
                nutrition: 1,
                sport: 1,
                alcohol: 1,
                smoke: 0,
                hereditary_diabetes: 0,
                diseases: 0,
                operations: 0,
                allergies: 0,
                medicines: 0,
                hypertension: 0,
                asthma: 0,
                diabets: 0,
                breast: 0,
                heart: 0,
                gender: ''
            },
            rules: {
                username: [
                    { required: true, message: 'Please input username', trigger: 'blur' },
                    { min: 3, max: 15, message: 'Length should be 4 to 15', trigger: 'blur' }
                ],
                pwd: [
                    { required: true, message: 'Please input password', trigger: 'blur' },
                    { min: 6, max: 15, message: 'Length should be 6 to 15', trigger: 'blur' }
                ],
                first_name: [
                    { required: true, message: 'Please input first name', trigger: 'blur' }
                ],
                last_name: [
                    { required: true, message: 'Please input last name', trigger: 'blur' }
                ],
                date1: [
                    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                ],
                location: [
                    { required: true, message: 'Please input location', trigger: 'blur' }
                ],
                did: [
                    { required: true, message: 'Please input device id', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let headers = { "Authorization": "Token " + sessionStorage.getItem('key') }
                    let data = new FormData()
                    if (document.querySelector('#avatar').files[0]) {
                        data.append('avatar', document.querySelector('#avatar').files[0])
                    }
                    data.append('first_name', this.ruleForm.first_name)
                    data.append('last_name', this.ruleForm.last_name)
                    data.append('username', this.ruleForm.username)
                    data.append('birth_date', moment(String(this.ruleForm.date1)).format('YYYY-MM-DD'))
                    data.append('location', this.ruleForm.location)
                    data.append('password', this.ruleForm.pwd)
                    data.append('device_id', this.ruleForm.did)

                    data.append('psychotropic_drugs', this.ruleForm.psychotropic_drugs);
                    data.append('emotional_state', this.ruleForm.emotional_state);
                    data.append('depression', this.ruleForm.depression);
                    data.append('eat', this.ruleForm.eat);
                    data.append('nutrition', this.ruleForm.nutrition);
                    data.append('sport', this.ruleForm.sport);
                    data.append('alcohol', this.ruleForm.alcohol);
                    data.append('smoke', this.ruleForm.smoke);
                    data.append('hereditary_diabetes', this.ruleForm.hereditary_diabetes);
                    data.append('diseases', this.ruleForm.diseases);
                    data.append('operations', this.ruleForm.operations);
                    data.append('allergies', this.ruleForm.allergies);
                    data.append('medicines', this.ruleForm.medicines);
                    data.append('hypertension', this.ruleForm.hypertension);
                    data.append('asthma', this.ruleForm.asthma);
                    data.append('diabets', this.ruleForm.diabets);
                    data.append('breast', this.ruleForm.breast);
                    data.append('heart', this.ruleForm.heart);
                    data.append('gender', this.ruleForm.gender);

                    axios.post('users/create/', data, { headers })
                        .then(r => {
                            console.log(r)
                            this.$message({
                                message: 'Пациент добавлен',
                                type: 'success'
                            });
                        }, r => {
                            console.log(r)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    }
}
</script>

<style scoped>
.section {
    width: 100%;
    overflow: hidden;
}

.demo-ruleForm {
    margin-top: 20px;
    margin-left: 10px;
}

.avatar-uploader {
    width: 178px;
    margin: 0 0 20px 20px;
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-select {
    width: 100%;
}
</style>